import React, { ReactNode } from "react";
import Button, { ButtonProps } from "domains/ui/components/Button";
import Icon, { IconId, IconProps } from "domains/ui/components/Icon";

import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";

export type HeaderAltAction = {
  id?: string;
  label: string | ReactNode;
  primaryIcon?: IconId;
  rightIcon?: IconId;
  buttonProps?: ButtonProps;
};

interface HeaderProps {
  title: string;
  thumbnail?: string;
  mainActions?: {
    label: string | ReactNode;
    icon?: IconId;
    iconProps?: Omit<IconProps, "id">;
    buttonProps?: ButtonProps;
  }[];
  altActions?: HeaderAltAction[];
}

export default function Header({
  title,
  thumbnail,
  mainActions,
  altActions,
}: HeaderProps) {
  return (
    <Flex direction="column" gap={6} w="100%" pt={5} pb={2} px={9}>
      <VStack align="left" gap={6}>
        <HStack align="center" justify="space-between">
          <HStack align="center" justify="start" gap={2}>
            {thumbnail && (
              <Image
                maxW="64px"
                maxH="64px"
                borderRadius={"lg"}
                alt="header thumbnail"
                src={thumbnail}
              />
            )}
            <Text size="title.lg">{title}</Text>
          </HStack>

          <HStack align="center" justify="start" gap={3}>
            {!!mainActions &&
              mainActions.map((mainAction, index) => (
                <React.Fragment key={`mainAction-${mainAction.label}`}>
                  <Button
                    size="sm"
                    leftIcon={
                      mainAction.icon && (
                        <Icon id={mainAction.icon} {...mainAction.iconProps} />
                      )
                    }
                    {...mainAction.buttonProps}
                  >
                    {mainAction.label}
                  </Button>

                  {index !== mainActions.length - 1 && (
                    <Divider h={6} orientation="vertical" />
                  )}
                </React.Fragment>
              ))}
          </HStack>
        </HStack>

        {altActions && (
          <SimpleGrid
            mt="0 !important"
            columns={{ sm: 2, md: 3, xl: 4 }}
            spacing={2}
          >
            {altActions.map((action, idx) => (
              <Button
                key={action.id || `${idx}`}
                variant="secondary"
                w="100%"
                bg="whiteAlpha.50"
                borderRadius="xl"
                leftIcon={
                  action.primaryIcon && (
                    <Icon
                      h="22px"
                      pl={1}
                      pr={3}
                      id={action.primaryIcon}
                      color="primary.500"
                      filter="drop-shadow(0px 0px 20px rgb(0 136 204)) drop-shadow(0px 0px 20px rgb(0 136 204 / 0.2))"
                    />
                  )
                }
                rightIcon={action.rightIcon && <Icon id={action.rightIcon} />}
                h={"62px"}
                {...action.buttonProps}
              >
                <Box
                  flex="1"
                  overflow="hidden"
                  textAlign="left"
                  textOverflow={"ellipsis"}
                >
                  {action.label}
                </Box>
              </Button>
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </Flex>
  );
}
