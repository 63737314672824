import React from "react";
import useAssetSort from "domains/assets/hooks/useAssetSort";
import useAllCanvas from "domains/canvasv2/hooks/useAllCanvas";
import { useFileCanvasHandler } from "domains/canvasv2/hooks/useFileCanvasHandler";
import { PersistedStateKey } from "domains/commons/hooks/usePersistedState";
import FileManager from "domains/file-manager/components/FileManager";

export default function CanvasList() {
  const { allAssetsSortArgs, assetFileManagerSortOptions } = useAssetSort();
  const { files, isLoading, hasMore, loadMore } = useAllCanvas({
    ...allAssetsSortArgs,
  });

  const canvasImageHandler = useFileCanvasHandler({});

  return (
    <FileManager
      files={files}
      isLoading={isLoading}
      hasMore={hasMore}
      loadMore={loadMore}
      fileHandlers={{
        canvas: canvasImageHandler,
      }}
      options={{
        gridView: "fill",
        cacheKey: PersistedStateKey.FILE_MANAGER_CANVAS,
        canSelect: true,
        canChangeNumberOfColumns: true,
        canChangeView: false,
        showFileNames: "always",
        ...assetFileManagerSortOptions,
      }}
    />
  );
}
