import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebounce } from "domains/commons/hooks/useDebounce";
import { mapAssetsToCanvasFiles } from "domains/file-manager/interfaces";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import { useUser } from "domains/user/hooks/useUser";
import { GetAssetsApiArg, useGetAssetsQuery } from "infra/api/generated/api";

import { skipToken } from "@reduxjs/toolkit/dist/query";

type UseAllCanvasArgs = {
  collectionId?: string;
  sortBy?: GetAssetsApiArg["sortBy"];
  sortDirection?: GetAssetsApiArg["sortDirection"];
  skip?: boolean;
};

export default function useAllCanvas({
  collectionId,
  sortBy,
  sortDirection,
  skip,
}: UseAllCanvasArgs) {
  const { selectedTeam } = useTeamContext();
  const [paginationToken, setPaginationToken] = useState<string | undefined>();
  const { isNsfwFilterEnabled } = useUser();

  const debouncedRequestArgs = useDebounce<GetAssetsApiArg | typeof skipToken>(
    !skip
      ? {
          teamId: selectedTeam.id,
          paginationToken,
          pageSize: "100",
          type: "canvas",
          sortBy,
          sortDirection,
        }
      : skipToken,
    100
  );

  useEffect(() => {
    setPaginationToken(undefined);
  }, [collectionId, sortBy, sortDirection]);

  const { data, isLoading } = useGetAssetsQuery(debouncedRequestArgs);

  const loadMore = useCallback(() => {
    if (data?.nextPaginationToken) {
      setPaginationToken(data?.nextPaginationToken);
    }
  }, [data?.nextPaginationToken]);

  const files = useMemo(() => {
    let assets = data?.assets ?? [];
    if (collectionId) {
      assets = assets.filter((asset) => {
        return asset.collectionIds.includes(collectionId);
      });
    }
    return mapAssetsToCanvasFiles(assets, isNsfwFilterEnabled);
  }, [data?.assets, collectionId, isNsfwFilterEnabled]);

  return {
    files,
    loadMore,
    hasMore: !!data?.nextPaginationToken,
    isLoading,
  };
}
