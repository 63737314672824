import { useState } from "react";
import { useRouter } from "next/router";
import CanvasListEmptyState from "domains/canvasv2/components/CanvasListEmptyState";
import {
  LOCKED_ASSET_GENERATOR_ID,
  LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY,
  useCanvasLock,
} from "domains/canvasv2/hooks/useCanvasLock";
import AssetCollection from "domains/collections/components/AssetCollection";
import DefaultFilePreview, {
  FilePreviewProps,
} from "domains/file-manager/components/FilePreview";
import { FileCanvasType, FileHandler } from "domains/file-manager/interfaces";
import { useScenarioToast } from "domains/notification/hooks/useScenarioToast";
import { useTeamContext } from "domains/teams/contexts/TeamProvider";
import Button from "domains/ui/components/Button";
import ButtonWithModal from "domains/ui/components/ButtonWithModal";
import Icon from "domains/ui/components/Icon";
import { AnalyticsEvents } from "infra/analytics/constants/Events";
import Track from "infra/analytics/Track";
import { useHandleApiError } from "infra/api/error";
import {
  useCopyAssetByAssetIdMutation,
  useDeleteAssetMutation,
} from "infra/api/generated/api";

import { Box, Flex, Tooltip } from "@chakra-ui/react";

interface FileCanvasHandlerProps {
  emptyState?: JSX.Element;
}
interface ExpiredLockPreviewProps {
  file: FileCanvasType;
  children: React.ReactNode;
}

export const useFileCanvasHandler = ({
  emptyState,
}: FileCanvasHandlerProps): FileHandler<FileCanvasType> => {
  const router = useRouter();
  const [triggerDeleteAsset, { isLoading: isLoadingDeleteAssetById }] =
    useDeleteAssetMutation();
  const [triggerCopyAsset] = useCopyAssetByAssetIdMutation();
  const { selectedTeam } = useTeamContext();
  const handleApiError = useHandleApiError();
  const { successToast } = useScenarioToast();
  const [isLoadingCopyAssetById, setIsLoadingCopyAssetById] = useState(false);

  const getGeneratorId = async (url: string) => {
    const response = await fetch(url);
    const json = await response.json();
    sessionStorage.setItem(LOCKED_ASSET_GENERATOR_ID, json.generatorId);
  };

  const ExpiredLockPreview = ({ file, children }: ExpiredLockPreviewProps) => {
    const { isAssetLocked, setDisplayLockModal } = useCanvasLock();
    const handleOpenLockCanvas = async (e: React.MouseEvent) => {
      e.preventDefault();
      await getGeneratorId(file.meta.url);
      sessionStorage.setItem(LOCKED_ASSET_ID_TO_FORCE_UNLOCK_KEY, file.id);
      setDisplayLockModal(true);
    };

    if (isAssetLocked(file.id, file.meta?.metadata?.lockExpiresAt)) {
      return (
        <Box onClick={handleOpenLockCanvas}>
          <Box filter="blur(5px)">{children}</Box>
          <Flex
            pos="absolute"
            top="0"
            right="0"
            bottom="0"
            left="0"
            align="center"
            justify="center"
            data-outside-click-excluded={true}
          >
            <Tooltip
              hasArrow={true}
              label={"This project is currently locked by another user"}
              placement="auto"
            >
              <Icon
                transform={"translate(0, -20%)"}
                w="50%"
                h="50%"
                id="Ui/Lock"
                filter="drop-shadow(0px 0px 2px rgb(0 0 0))"
              />
            </Tooltip>
          </Flex>
        </Box>
      );
    } else {
      return <>{children}</>;
    }
  };

  const FileCanvasPreview = (props: FilePreviewProps<FileCanvasType>) => {
    return (
      <Box>
        <ExpiredLockPreview file={props.file}>
          <DefaultFilePreview {...(props as any)} />
        </ExpiredLockPreview>
      </Box>
    );
  };

  const handleDelete = async (files: FileCanvasType[]) => {
    try {
      const ids = files.map((file) => file.id);
      // split by array of 100 ids
      const chunks = [];
      for (let i = 0; i < ids.length; i += 100) {
        chunks.push(ids.slice(i, i + 100));
      }
      for (const chunk of chunks) {
        await triggerDeleteAsset({
          body: {
            assetIds: chunk,
          },
          teamId: selectedTeam.id,
        });
      }

      Track(AnalyticsEvents.CanvasV2.Delete, {
        assetIds: ids,
      });

      successToast({
        title: "Projects deleted",
      });
    } catch (error) {
      handleApiError(error, "There was an error deleting the canvas projects");
    }
  };

  const handleDuplicate = async (files: FileCanvasType[]) => {
    setIsLoadingCopyAssetById(true);
    for (const file of files) {
      try {
        await triggerCopyAsset({
          teamId: selectedTeam.id,
          assetId: file.meta.id,
        }).unwrap();
        Track(AnalyticsEvents.CanvasV2.Duplicate, {
          assetId: file.meta.id,
        });
      } catch (error) {
        handleApiError(error, "There was an error duplicating the canvas");
      }
    }
    setIsLoadingCopyAssetById(false);
  };

  return {
    // TODO: enable when we are sure generator list and asset gallery are integrated into file manager fully
    EmptyState: emptyState ?? <CanvasListEmptyState />,
    FilePreview: FileCanvasPreview,
    actions: [
      {
        kind: ["selectionBar"],
        label: "Duplicate",
        Component: ({ onAction }) => (
          <Button
            colorScheme={"danger"}
            isLoading={isLoadingCopyAssetById}
            leftIcon={<Icon id="Ui/Copy" />}
            onClick={onAction}
            variant="secondary"
          >
            Duplicate
          </Button>
        ),
        onAction: handleDuplicate,
      },
      {
        kind: ["selectionBar"],
        label: "Delete",
        Component: ({ files, onAction }) => (
          <ButtonWithModal
            variant="secondary"
            colorScheme={"danger"}
            leftIcon={<Icon id="Ui/Trash" />}
            onConfirm={onAction}
            modalHeader={`Delete Project${files.length > 1 ? "s" : ""}`}
            modalBody={`Are you sure you want to delete ${
              files.length > 1 ? "these" : "this"
            } project${files.length > 1 ? "s" : ""}?`}
            isLoading={isLoadingDeleteAssetById}
            isModalConfirmButtonLoading={isLoadingDeleteAssetById}
            isDisabled={isLoadingDeleteAssetById}
          >
            Delete
          </ButtonWithModal>
        ),
        shortcut: (e: KeyboardEvent) =>
          e.key === "Delete" || e.key === "Backspace",
        onAction: handleDelete,
      },
      {
        kind: ["selectionBar"],
        label: "Collections",
        onAction: (_) => {},
        Component: ({ files }) => (
          <AssetCollection
            assets={files.map((item) => item.meta)}
            menuPlacement="top"
          />
        ),
      },
    ],
    onOpen: async (file: FileCanvasType) => {
      const response = await fetch(file.meta.url);
      const json = await response.json();
      return router.push({
        pathname: "/canvas/[id]",
        query: {
          id: file.id,
          generatorId: json.generatorId,
        },
      });
    },
  };
};
