import { useCallback, useEffect, useMemo } from "react";
import { NextRouter, useRouter } from "next/router";
import { extractFirstQueryParam } from "domains/commons/misc";
import _ from "lodash";

export interface Tabs<T extends string = string> {
  ids: T[];
  items: {
    [id in T]: string;
  };
}

export const createTabs = <T extends string>(tabs: Tabs<T>): Tabs<T> => {
  return tabs;
};

export const useTabs = <T extends string>(tabs: Tabs<T>, defaultTabId?: T) => {
  const router = useRouter();

  const isRouterReady = router.isReady;
  const routerQueryTab = extractFirstQueryParam(router.query.tab);

  const { tabIndex, tabId } = useMemo(() => {
    if (!isRouterReady) {
      return { tabId: undefined, tabIndex: undefined };
    }

    const tabId =
      (_.findKey(tabs.items, (val) => val === (routerQueryTab || "")) as T) ||
      defaultTabId;
    return {
      tabId,
      tabIndex: tabId ? tabs.ids.indexOf(tabId) : undefined,
    };
  }, [isRouterReady, tabs, routerQueryTab, defaultTabId]);

  const onTabChange = useCallback(
    (index: number) => {
      if (tabs.items[tabs.ids[index]] === router.query.tab) return;
      changeRouteQueryTab(router, tabs, index);
      const newUrlObj = {
        pathname: router.pathname,
        query: router.query,
      };
      void router.push(newUrlObj);
    },
    [router, tabs]
  );

  useEffect(() => {
    if (!router.isReady) return;
    if (tabIndex !== undefined) return;
    changeRouteQueryTab(router, tabs, 0);
    const newUrlObj = {
      pathname: router.pathname,
      query: router.query,
    };
    void router.replace(newUrlObj);
  }, [router, tabs, tabIndex]);

  return { tabId, tabIndex, onTabChange };
};

const changeRouteQueryTab = (router: NextRouter, tabs: Tabs, index: number) => {
  const newTabId = tabs.ids[index];
  const newQueryTab = tabs.items[newTabId];
  if (!newQueryTab) delete router.query.tab;
  else router.query.tab = newQueryTab;
};
